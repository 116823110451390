<template>
  <div class="page">
    <div class="m-t-10 section d-flex flex-column">
      <div class="flex-column r-gap-15">
        <div class="flex jc-center"><h1 class="bold">{{ $t('legion.rules.title') }}</h1></div>
        <div class="bold">{{ $t('legion.rules.1') }}</div>
        <div>{{ $t('legion.rules.2') }}</div>
        <div>{{ $t('legion.rules.3') }}</div>
        <div>{{ $t('legion.rules.4') }}</div>
        <div>{{ $t('legion.rules.5') }}</div>
        <div>{{ $t('legion.rules.6') }}</div>
        <div>{{ $t('legion.rules.7') }}</div>
        <div>{{ $t('legion.rules.8') }}</div>
        <div>{{ $t('legion.rules.9') }}</div>
        <div>{{ $t('legion.rules.10') }}</div>
        <div>{{ $t('legion.rules.11') }}</div>
        <div>{{ $t('legion.rules.12') }}</div>
        <div>{{ $t('legion.rules.13') }}</div>
        <div>{{ $t('legion.rules.14') }}</div>
        <div>{{ $t('legion.rules.15') }}</div>
        <div>{{ $t('legion.rules.16') }}</div>
        <div>{{ $t('legion.rules.17') }}</div>
        <div>{{ $t('legion.rules.18') }}</div>
        <div>{{ $t('legion.rules.19') }}</div>
        <div>{{ $t('legion.rules.20') }}</div>
        <div class="bold">{{ $t('legion.rules.21') }}</div>
        <div>{{ $t('legion.rules.22') }}</div>
        <div>{{ $t('legion.rules.23') }}</div>
        <div>{{ $t('legion.rules.24') }}</div>
        <div>{{ $t('legion.rules.25') }}</div>
        <div>{{ $t('legion.rules.26') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      process: process.env.VUE_APP_BSC
    };
  },
  computed: {
    ...mapState({
      sets: (state) => state.sets,
    }),
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
.section {
  width: 100%;
  min-height: 500px;
  line-height: 26px;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  padding: 30px;
}

</style>